/* @leny/bowlstats
 *
 * /src/styles.scss
 */

.is-split-frame {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        border: 2px solid hsl(348, 100%, 61%);
        border-radius: 100%;
    }
}

.is-va-middle {
    vertical-align: middle;
}

.is-size-8 {
    font-size: .5em !important;
}

.is-relative {
    position: relative;
}

.is-calendar-year {
    position: absolute;
    transform: rotate(-90deg);
    top: .1rem;
    left: 0;
}

.mt-neg-6 {
    margin-top: -3.5rem !important;
}

.is-flex-1{
    flex: 1;
}

.is-no-list {
    list-style: none;
}

table {table-layout: fixed;}
